import React, {Component} from 'react';
import './product.css';
import LoyaltyplexPrivacy from './LoyaltyplexPrivacy';
import LoyaltyplexFAQ from './LoyaltyplexFAQ';
import {Link, Route} from 'react-router-dom';
import { withRouter } from 'react-router-dom';

class Loyaltyplex extends Component {
    render() {

        const {location: {pathname}} = this.props;

        let currentData = <span/>;
        if (pathname === '/loyaltyplex/' ||  pathname === '/loyaltyplex') {
          currentData = (
              <div className="app-details">
                <div className="product-sub-heading min-head">
                  Supercharge your rewards program !!
                </div>
                <a href="https://apps.shopify.com/loyaltyplex" target="_blank">
                <div className="product-sub-heading add-shopify-btn">

                 Install now on <img className="shopify-logo-loyaltyplex" src="logo/shopify.png"/> app store.
            
                </div>
                     </a>
                <div className="product-intro-container">
                  <img src="loyaltyplex/app_intro_latest.png"/>
                </div>
            <div>
            E-commerce for small businesses today is a story of thousands of options competing for your customer's attention.
            </div>
            <div>
             Spending money on ads for customer acquisition or cutting margins will quickly eat away into profits and is unsustainable when competing against e-commerce behemoths with seemingly endless resources.
            </div>
            <div>
            For a small store to succeed in this environment, the only sure-shot way is to build a loyal base of repeat customers.
            </div>

            <div className="app-section-heading">
            Introducing Loyaltyplex
            </div>
            <div>
              Loyaltyplex lets you create gamified rewards program with increasing amounts of discount for each level the customer is in.
              This makes shopping in your store fun and engaging as well as ioncentivizes your customers to spend more in order to rise up to the higher levels of your program.
            </div>
            <div className="app-section-heading">
                Gamify your rewards
                </div>
                <div className="app-section-body">
                The most engaging experiences are the ones in which the next tiers are not too easy to reach while not being too inaccessible either. Define as many tiers or as few as you like for no extra charge.
                </div>
                <div className="app-section-heading">
                Interactive Ops Center
                </div>
                <div className="app-section-body">
                Upon installing the plugin, you also get free access to Loyaltyplex interactive ops-center, where you can visualize customer retention metrics, update your plugin theme or update your program policy whenever you like,
                </div>
                <div className="app-section-heading">
                Flat fees, no hidden charges
                </div>
                <div>
                The free version of the app lets you create upto 5 levels of rewards.
                <p>
                  In addition, the premium version lets you create unlimited levels of rewards, allowing you to finetune your program 
                  based on your catalog type, as well as allows for custom plugin themes and allows you to view important metrics regarding the
                  performance of your store.
                </p>
                Service as many customers as you like and define as many tiers to your program as you'd like for no extra charge.
                </div>
                <div className="app-section-heading">
                How it works
                </div>
                <div>
                Consider a sample policy might look like-
                
                0-10 Credits 2% cashback in rewards
                11-50 Credits 2.4%
                ...
                
                According to this sample, a new customer will be tier 1 and earn 2% cash back in the form of loyalty points on making a purchase which they can either redeem on a subsequent purchase or accumulate more to move up to the next tier and earn higher % credits on subsequent purchases until they move further up.
                </div>
                <div className="app-esction-heading">
                Keep 'em coming back for more.
                </div>
                <div className="app-section-body">
                Once a customer has made a purchase they will return either to redeem their rewards or to earn more rewards to move up to a higher tier. Either way, it means more sales for your store and higher engagement for your customers.
                </div>
                
                <div className="app-section-heading">
                Specifications
                </div>
                
                Loyaltyplex is a close-loop rewards system, meaning rewards earnt by customers can only be spent at your store.
                The plugin does not use any external authentication and uses your store's login to maintain customer session. Hence you need to enable register/login functionality in order for your customers to earn rewards while shopping at your store. To enable customer login in your admin console-> click on Settings->Checkout->Accounts. Normal checkout flows will still work as expected even for disabled/guest users.
                The app is lightweight and the entire functionality is implemented using script tags in conjunction with price-rules, this means that the expenses can be audited easily and your reporting/audit system will not be affected by any of the plugin functionality.
                Points are earnt on the final total after applying any discount (Excluding taxes and shipping)
                So sign-up for a free trial today, define your loyalty policy and watch as they can't get enough and keep coming back for more.
    
            </div>
            )
        }
        return (
            <div className="container product-container">
            <div className="h2">
              Loyaltyplex <span className="product-sub">Keep em coming back for more.</span>
            </div>

            {currentData}

            <div className="section-footer row">
              <div className="col-lg-4">
                 <a href="https://apps.shopify.com/loyaltyplex" target="_blank">Visit in app store.</a>
              </div>
              <div className="col-lg-4">
                 <Link to="/loyaltyplex/privacy">Privacy Policy</Link>
              </div>
              <div className="col-lg-4">
                 <Link to="/loyaltyplex/faq">FAQs</Link>
              </div>
            </div>
            
            <Route path={`/loyaltyplex/privacy`} component={LoyaltyplexPrivacy}/>
            <Route path={`/loyaltyplex/faq`} component={LoyaltyplexFAQ}/>
            
            </div>
            )
    }
}

export default withRouter(Loyaltyplex);