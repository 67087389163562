import React, {Component} from 'react';
import {Row, Col, Button, ListGroup, ListGroupItem, Grid} from 'react-bootstrap';
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import LoyaltyplexCarousel from './LoyaltyplexCarousel';
import CartshareCarousel from './CartshareCarousel';

export default class ProductsSection extends Component {
  render() {
    return (
      <div style={{"marginTop": "20px"}}>
      <Grid>
      
   <div className="h2 section-heading">Products</div>
          <CartshareCarousel />
          <LoyaltyplexCarousel/>

          </Grid>
      </div>
      )
  }
}