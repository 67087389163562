import React, {Component} from 'react';
import LandingCarousel from './LandingCarousel';
import ProductsSection from './ProductsSection';
import ServicesSection from './ServicesSection';

import {Grid} from 'react-bootstrap';
import './landing.css';


export default class WebLanding extends Component {
  render() {
    return (
      <div className="landing-container">

        <LandingCarousel/>

        <ProductsSection/>

        
        <ServicesSection/>

      </div>
      )
  }
}