import React, {Component} from 'react';
import CartshareFAQ from './CartshareFAQ';
import CartsharePrivacy from './CartsharePrivacy';

import {Link, Route} from 'react-router-dom';
import { withRouter } from 'react-router-dom';

class Cartshare extends Component {
    render() {

        const {location: {pathname}} = this.props;

        let currentData = <span/>;
        if (pathname === '/cartshare/' ||  pathname === '/cartshare') {
          currentData = (
              <div className="app-details">
                <div className="product-sub-heading min-head">
                  Social Shopping through incentives.
                </div>
                <a href="https://apps.shopify.com/cartshare" target="_blank">
                <div className="product-sub-heading add-shopify-btn">
                    Coming soon to app store.
                </div>
                </a>
                <div style={{"textAlign": "center", "marginTop": "10px", "marginBottom": "10px"}}>
                    <img src="https://s3-us-west-2.amazonaws.com/cartshare.kashmerelabs.com/resources/cartshare_image.png"/>
                </div>
                <div>
                <h3> Shopping should be a social experience</h3>
                People want to know where our friends and influencers are shopping. We trust the brands where their friends and family are buying and we want to buy the same products which are influencers are buying.
                </div>
                <div>
                <h3> Let your customers advertise your store.</h3>
                Every time someone shops on your store and shares their cart on social-media your store gets access to their entire social graph of friends and followers.
                </div>
                <div>
                  <h3>CartShare lets you create incentives for social sharing</h3>
                When the principal (user who creates a new cart) makes a checkout at your store, they get the unique link for their checkout in the storefront plugin and also receive an email with the CartShare url.
                The principal can now share this link via a unique url or ready-made cards on social media which can then be accessed by their friends and followers.
                When people in their social-media graph follow the link, it will populate the follower's cart with the same items as purchased by the principal.
                The follower can change the size/variants/quantity of items in the cart, but cannot add or remove items from the cart in order to be eligible for cash-back.
                Once the follower completes their checkout both the principal and the follower will get store credit as specified by your store policy.
                the store-credits can be then redeemed using an easy to use store-front plugin.
                </div>
                <div>
                <h3>Makes sharing checkouts a breeze-</h3>
                Every time a purchase is made the app generates rich cards for social media networks like Twitter, Facebook, Pinterest or direct messaging with mobile friendly
                cart previews which makes sharing on social media easy and simple while also expanding the reach of your store to multitudes of new potential customers.
                </div>
            </div>
            )
        }
        return (
            <div className="container product-container">
            <div className="h2">
              Cartshare 
            </div>

            {currentData}

            <div className="section-footer row">
              <div className="col-lg-4">
                 <a href="https://apps.shopify.com/cartshare" target="_blank">Coming soon.</a>
              </div>
              <div className="col-lg-4">
                 <Link to="/cartshare/privacy">Privacy Policy</Link>
              </div>
              <div className="col-lg-4">
                 <Link to="/cartshare/faq">FAQs</Link>
              </div>
            </div>
            
            <Route path={`/cartshare/privacy`} component={CartsharePrivacy}/>
            <Route path={`/cartshare/faq`} component={CartshareFAQ}/>
            
            </div>
            )
    }
}

export default withRouter(Cartshare);

                