import React, {Component} from 'react';
import Slider from "react-slick";
import {Grid, Row, Col} from 'react-bootstrap';

export default class LandingCarousel extends Component {
  render() {
    return (
      <Grid>
      <div className="product-service-container">
        <Row>
          <Col lg={6}>
          <div className="top-text-container">
          <div className="h3">
            <div>Intersectional solutions</div>
            <div>for the modern web.</div>
          </div>
          <div className="top-subtext">
            Combining Web and E-Commerce stacks with state of the art NLP and Distributed Ledger Technology to create data-driven products and services.
          </div>
          <div className="top-label-1" href="https://www.shopify.com/?ref=eigensmatrix">
            <div className="logo-label">Now Building on </div>
            <div className="logo-img-container"><img src={"logo/shopify.png"} className="logo-img"/></div>
          </div>
          </div>
        </Col>
        <Col lg={6}>
        <div className="top-carousel-container">
        <Slider autoplay={true} dots={true} speed={500} autoplaySpeed={4000} arrows={false}>
          <div className="top-img-container">
            <img src="carousel/Group1.png" className="carousel-image"/>
          </div>
          <div className="top-img-container">
            <img src="carousel/Group2.png" className="carousel-image"/>
          </div>
          <div className="top-img-container">
            <img src="carousel/Group3.png" className="carousel-image"/>
          </div>
          <div className="top-img-container">
            <img src="carousel/Group4.png" className="carousel-image"/>
          </div>
          <div className="top-img-container">
            <img src="carousel/Group5.png" className="carousel-image"/>
          </div>
        </Slider>
        </div>
        </Col>
        </Row>
      </div>
      </Grid>
      )
  }
}