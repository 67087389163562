import React, {Component} from 'react';
import {Grid, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';

export default class LoyaltyplexFAQ extends Component {
  render() {
    return (
      <Grid>
      <Row className="faq-container">
        <Col lg={12} style={{"marginBottom": "40px"}}>
          <div className="faq-head">
            What is Loyaltyplex?
          </div>
          <div className="faq-tail">
            Loyaltyplex is a plugin to your store-front which allows you to create unique and interesting loyalty and rewards programs for your customers.
            Simply install the plugin from the app-store, setup your program policy and your customers can start earning rewards for each purchase.
            It is completely whitelabelled with a native look and feel and uses your store login to authenticate customers. Furthermore it also allows you to customize your plugin to match your store theme.
          </div>
  
          <div className="faq-head">
            How does it work?
          </div>
          <div className="faq-tail">
            Upon each purchase customer earns cashback (Loyalty points) to spend on your store.
            For instance according to the sample policy, a new customer will be level 1 and earn 4% cashback in the form of loyalty points which they can either redeem on a subsequent purchase or accumulate more to level-up.
            On the next purchase the customer can either redeem their points or accumulate more to move up to the next level and earn 6% credits on subsequent purchases until they move to the next level.
            Points are close-loop, meaning points earnt at your store can only be spent at your store.
            Points are earnt on the final order total after applying discount (Excluding taxes and shipping)
          </div>
          
          <div className="faq-head">
            What merchant and customer data do your store?
          </div>
          <div className="faq-tail">
            We subscribe to Admin APIs for reading orders, reading and writing script-tags and for programatically creating discount coupons to your store.
            We also save store details and customer details of customers using the plugin in-order to maintain customer credit and session information.
            Please refer to our <Link to="/privacy">Privacy Policy</Link> for more details.
          </div>
          <div className="faq-head">
            What happens to pending credits of customers if I uninstall the app?
          </div>
          <div className="faq-tail">
            If you uninstall the Loyaltyplex app, your customers will no longer be able to redeem any pending credits on your store and
            you[the store owner] will be responsible for honoring any unfulfilled reward points.
            Upon request [through Shopify mandatory webhook] you can get customer data and  decide a course of action. However beyond the period of time
            mandated by Shopify we will not store any store or customer data.
          </div>          
          <div className="faq-head">
            Do you share my data with any third-party?
          </div>
          <div className="faq-tail">
            No. We do not share your data with any third party.
          </div>
          
          <div className="faq-head">
            Are there any requirements for installing this app?
          </div>
          <div className="faq-tail">
            You need to have a shopify online store to install this app.
            Furthermore you need to enable customer login for customers to begin earning rewards from their purchases.
            To enable customer login, login to your Admin control panel-> go to Settings -> Checkout -> Customer Accounts.
            Click on Make Accounts optional. Customers can still use the normal functionalities offered by your store without logging in and 
            even if you have Customer Accounts disabled.
          </div>
          
          <div className="faq-head">
            Do you put your branding anywhere on the plugin?
          </div>
          <div className="faq-tail">
            No. The plugin is completely whitelabelled and the integration is near native.
            The plugin comes with a default theme but you can customize the plugin to exactly match your store theme.
          </div>
          
          <div className="faq-head">
            How do I login to the Ops center?
          </div>
          <div className="faq-tail">
            Upon installation, once you finish setting up your program policy, you will recieve a welcome email which contains instructions to 
            setup your password.
            If you forgot your password or cannot locate it, click on forgot password in the login page of https://loyaltyplex.com.
            Please check your Spam folder if you do not recieve the email.
            Furthermore you can also whitelist support@loyaltyplex.com in order to recieve future communication in a timely manner.
          </div>
          
          <div className="faq-head">
            How do I change my plugin theme?
          </div>
          <div className="faq-tail">
            Please login to ops-center at https://loyaltyplex.com/login and go to Themes tab. From there you can customize the look and feel of the plugin on your store.
            Click save to view the changes on your store in real-time.
          </div>
        </Col>
        </Row>
      </Grid>
      )
  }
}