import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import TopNavBar from './components/nav/TopNavBar';
import WebLanding from './components/main/WebLanding';
import BottomNavBar from './components/nav/BottomNavBar';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import AlicePrivacy from './components/policy/alice/Privacy';
import About from './components/policy/About';
import createBrowserHistory from "history/createBrowserHistory";
import Loyaltyplex from './components/main/product/Loylatyplex.js';
import Alice from './components/main/product/alice/Alice.js';
import Cartshare from './components/main/product/cartshare/Cartshare.js';


const history = createBrowserHistory()

class App extends Component {
  render() {
    return (
      <Router history={history}>  
        <div className="App">
          <TopNavBar/>
           <Switch>
              <Route exact path="/" component={WebLanding} />
              <Route path="alice/privacy" component={AlicePrivacy} />
              <Route path="/about" component={About}/>
              <Route path="/loyaltyplex" component={Loyaltyplex}/>
              <Route path="/alice" component={Alice}/>
              <Route path="/cartshare" component={Cartshare}/>
            </Switch>
          <BottomNavBar/>
        </div>
      </Router>
    );
  }
}

export default App;
