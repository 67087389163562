import React, {Component} from 'react';

import AlicePrivacy from './AlicePrivacy';
import AliceFAQ from './AliceFAQ';
import {Link, Route} from 'react-router-dom';
import { withRouter } from 'react-router-dom';

class Alice extends Component {
    render() {

        const {location: {pathname}} = this.props;

        let currentData = <span/>;
        if (pathname === '/alice/' ||  pathname === '/alice') {
          currentData = (
              <div className="app-details">
                <div className="product-sub-heading min-head">
                  Build Smarter Stores.
                </div>
                <a href="https://apps.shopify.com/alice" target="_blank">
                <div className="product-sub-heading add-shopify-btn">

                 Install now on <img className="shopify-logo-loyaltyplex" src="logo/alice_logo_shopify.png"/> app store.
            
                </div>
                     </a>

            <div>
           Do you ever get frustrated after spending hours upon hours manually cataloguing your products and arranging them into collections.
            </div>
            <div>
              Much of this can be automated with the help of AI and decision making tools.
            </div>

            <div className="app-section-heading">
            Introducing Alice.
            </div>
            <div>
              Alice let's you create smart collections and import products into them automatically.
            </div>
            <div className="app-section-heading">
                Automate keyword and tag creation-
                </div>
                <div className="app-section-body">
Alice uses deep text analysis on the product description and titles to create ranked maps of similar products along with keywords which the products map to.

                </div>
                <div className="app-section-heading">
              Bulk import products into collections
                </div>
                <div className="app-section-body">
The app then further allows you to create collections and bulk import products into the relevant collections with a single click of a button thus automating the entire process.
                </div>
                <div className="app-section-heading">
               View Search trends for products and collections.
                </div>
                <div>
The app also allows you to view current search trends for the newly generated and existing collections so that you can decide which collections you can feature prominently in your store.

                </div>

                
                <div className="app-section-heading">
                Say goodbye to manual cataloguing.
                </div>
                <div className="app-section-body">
                All this without spending any time manually cataloging products and dividing them into custom collections
                and with more power and flexibility than smart collection which requires you to know and provide the keywords beforehand.
                </div>
            </div>
            )
        }
        return (
            <div className="container product-container">
            <div className="h2">
              Alice <span className="product-sub">Build smarter stores..</span>
            </div>

            {currentData}

            <div className="section-footer row">
              <div className="col-lg-4">
                 <a href="https://apps.shopify.com/alice" target="_blank">Visit in app store.</a>
              </div>
              <div className="col-lg-4">
                 <Link to="/alice/privacy">Privacy Policy</Link>
              </div>
              <div className="col-lg-4">
                 <Link to="/alice/faq">FAQs</Link>
              </div>
            </div>
            
            <Route path={`/alice/privacy`} component={AlicePrivacy}/>
            <Route path={`/alice/faq`} component={AliceFAQ}/>
            
            </div>
            )
    }
}

export default withRouter(Alice);

                