import React, {Component} from 'react';
import {Row, Col, Button, ListGroup, ListGroupItem, Grid} from 'react-bootstrap';
import Slider from "react-slick";
import {Link} from 'react-router-dom';

export default class LoyaltyplexCarousel extends Component {
    render() {
        return (
            <div>
            
          <div className="product-section">
            <div className="product-banner">
              <div className="banner-heading">
                <Link to="/loyaltyplex">
                  <img src=""/>Loyaltyplex <span>Loyalty Program Addon for Shopify</span>
                </Link>
              </div>
              <a href="https://apps.shopify.com/loyaltyplex" target="_blank"><Button className="btn btn-default product-button">Check out in App-store</Button></a>
            </div>
          
          <div>
          <Row>
            <Col lg={6} xs={12}>
            <ListGroup className="listing-container">
            <ListGroupItem>
              Loyaltyplex let's you create gamified rewards programs for your customers.
            </ListGroupItem>
            <ListGroupItem>
               Get the freedom to define multiple levels of rewards and percentage of rewards for each level.
            </ListGroupItem>
            <ListGroupItem>
              Incentivise your customers to come back to your store either to redeem their rewards or earn more.
            </ListGroupItem>
            <ListGroupItem>
              Click of a button, setup and automatic discount application.
            </ListGroupItem>
            <ListGroupItem>
              No-more emailing discount coupons, streamlined all-in-one solution for rewards, loyalty and redemption.
            </ListGroupItem>
            <ListGroupItem>
              Create intricate rewards program without sacrificing your ability to audit.
            </ListGroupItem>
            </ListGroup>
            </Col>
            <Col lg={6} xs={12}>
              <Slider autoplay={true} dots={true} speed={1000} autoplaySpeed={4000} arrows={false}>
                <div >
                  <img src="products/lp_img_1.png" />
                </div>
                <div >
                  <img src="products/lp_img_2.png" />
                </div>
                <div >
                  <img src="products/lp_img_3.png" />
                </div>
                <div >
                  <img src="products/lp_img_4.png" />
                </div>
                <div >
                  <img src="products/lp_img_5.png" />
                </div>
              </Slider>            
            </Col>
          </Row>
          
          </div>
          </div>
          
          
            </div>
            )
    }
}