import React, {Component} from 'react';
import {Grid, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';

export default class AiceFAQ extends Component {
  render() {
    return (
      <Grid>
      <Row className="faq-container">
        <Col lg={12} style={{"marginBottom": "40px"}}>
          <div className="faq-head">
            What is Alice?
          </div>
          <div className="faq-tail">
            Alice is an app that automatically generates keywords and tags for your app based on deep text analysis
            of product title and description. It can then be used to create collections, bulk add products to collections, modify 
            your collections or view search trends for your collections.
          </div>
  
          <div className="faq-head">
            How does it work?
          </div>
          <div className="faq-tail">
            Alice does deep text analysis on your catalog list to find the highest ranking keywords and 
            allows you to visualize them in intuitive list form.
            It then allows you to create custom collections from the keywords and bulk add or remove products from the collections.
            
          </div>
          
          <div className="faq-head">
            What merchant and customer data do your store?
          </div>
          <div className="faq-tail">
            In addition to store account information,
            We also store the product and collection details in order to better manage your store.
            Please refer to our <Link to="/alice/privacy">Privacy Policy</Link> for more details.
          </div>
          <div className="faq-head">
            What happens to pending credits of customers if I uninstall the app?
          </div>
          <div className="faq-tail">
            If you uninstall the app, all store data will be permanently removed after 48 hours from uninstalling the app.
          </div>          
          <div className="faq-head">
            Do you share my data with any third-party?
          </div>
          <div className="faq-tail">
            No. We do not share your data with any third party.
          </div>
          
          <div className="faq-head">
            Are there any requirements for installing this app?
          </div>
          <div className="faq-tail">
            You need to have a shopify online store to install this app.
          </div>
          
        </Col>
        </Row>
      </Grid>
      )
  }
}