import React, {Component} from 'react';

export default class AlicePrivacy extends Component {
    render() {
        return (
            <div className="privacy-policy-container">
                <h4>Privacy policy</h4>
                <div>
                    Privacy policy for Alice.
                    <div>
                      Kashmere Labs Privacy Policy
<p>
    Alice "the App” provides automatic collection creation through keyword anaylysis to merchants who use Shopify to power their stores.
    This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App in connection with your Shopify-supported store.
</p>
<p>
    Personal Information the App Collects
</p>
<p>
    When you install the App, we are automatically able to access certain types of information from your Shopify account: 
    <div>
       <ul>
         <li>
           Product list and description.
         </li>
         <li>
           Collection list and description.
         </li>
         
       </ul>
    </div>
</p>

<p>
    How Do We Use Your Personal Information?
</p>
<p>
    We use the personal information we collect from you and your customers in order to provide the Service and to operate the App.
</p>
<p>
    Sharing Your Personal Information
</p>
<p>
Your information is not shared with any third party and is securely stored in our servers.
</p>
<p>
Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
</p>

<p>
  Your Rights If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
</p>
<p>
    Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.
</p>
<p>
  Data Retention when you add a product or a collection through the site.
  We will maintain your store product and collection information unless and until you ask us to delete the information or uninstall the app.
</p>
<p>
  Changes We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
</p>
<p>
  Contact Us For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at [[INSERT CONTACT EMAIL]] or by mail using the details provided below:
</p>
<p>
    <div>Address -</div> 
    <div>160 Robinson Road, #14-04 Singapore Business Federation Centre</div>
</p>


                    </div>
                </div>
            </div>
            )
    }
}