import React, {Component} from 'react';
import {Navbar, Nav, NavItem, NavDropdown, Grid, Button, Row, Col, Modal, FormControl, FormGroup} from 'react-bootstrap';
import axios from 'axios';
import './nav.css';
import {Link} from 'react-router-dom';

export default class TopNavBar extends Component {
  constructor(props) {
    super(props);
    this.state ={"showModal": false, message: ""};
    this.handleClose = this.handleClose.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  handleClose() {
        this.setState({"showModal": false});
  }
  
  toggleModal() {
    this.setState({"showModal": !this.state.showModal})
  }
  
  submitForm(state) {
    const self = this;
    axios.post('https://api.kashmerelabs.com/mailer', state).then(function(response){
      console.log(response);
      self.setState({"message": "Thanks for the info. We'll be in touch shortly."});
      setTimeout(function(){
        self.setState({"showModal": false});
      }, 2000);
    }).catch(function(err){
      
    })
  }
  render() {
    return (
      <div>
      <Navbar>
        <Col lg={3} xs={5} className="nav-col">
            <Link to="/">
              <Navbar.Brand>
                <img src={"logo/logo_white.png"} className="app-logo"/>
              </Navbar.Brand>
            </Link>
      </Col>
      <Col lg={4} xsHidden className="text-center nav-col">

      </Col>
      
        <Col lg={2} xs={6} className="nav-col">
          <div>
            <div className="btn btn-default req-quote-btn" onClick={this.toggleModal}>Request Quote</div>
          </div>
        </Col>
        <Col lg={1}>
         <div className="nav-right"><a href="https://kashmerelabs.com/products" target="_blank">Products</a></div>
        </Col>
        <Col lg={1} xs={1} className="nav-col">
          <div className="nav-right"><a href="https://tenminstech.com" target="_blank">Blog</a></div>
        </Col>
      </Navbar>
      
      <RequestQuoteModal showModal={this.state.showModal} handleClose={this.handleClose}
                         submitForm={this.submitForm} message={this.state.message}/>
      
      </div>
      )
  }
}

class RequestQuoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {"clientEmail" : "", "projectDescription": ""};
    this.submitForm = this.submitForm.bind(this);
    this.setProjectDescription = this.setProjectDescription.bind(this);
    this.setEmail = this.setEmail.bind(this);
  }
  submitForm() {
    this.props.submitForm(this.state);
  }
  
  setProjectDescription(e) {
    this.setState({"projectDescription": e.target.value});
  }
  setEmail(e) {
    this.setState({"clientEmail": e.target.value});
  }
  render() {
    const {showModal, handleClose, message} = this.props;
    return (
        <Modal show={showModal} className="contact-modal" onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Request a Quote
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Let's discuss your project.</p>
            <FormGroup>
              <FormControl type="text"
              placeholder="Enter email which you'd like to contact us with" value={this.state.clientEmail}
              onChange={this.setEmail}/>
              </FormGroup>
              <FormGroup>
             <FormControl componentClass="textarea" style={{height: '200px'}}
             placeholder="Enter a brief description of your project and requirements." 
             value={this.state.projectDescription} onChange={this.setProjectDescription}/>  
             </FormGroup>
             <p>Or <a href="https://calendly.com/roy-kl" target="_blank">Schedule a calendly meeting.</a></p>
          </Modal.Body>
          <Modal.Footer>
             <div className="message-success">{message}</div>
            <Button className="btn btn-default submit-btn" onClick={this.submitForm}>Submit</Button>
          </Modal.Footer>
        </Modal>   
      )
  }
}