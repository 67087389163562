import React, {Component} from 'react';
import {Grid, Col} from 'react-bootstrap';

export default class About extends Component {
    render() {
        return (
            <div>
                <Grid>
                    <Col xs={12} lg={6} lgOffset={3}>
                    <div className="about-container">

                    <div className="about-body">
                    <div>Hi, I am Roy.</div>
                    <p>
                        <div>I am a full-stack engineer and was over the past six years building and scaling web applications at 
                        Intel, Akamai and YugaByte.</div>
                    </p>
                    <p>
                        I went to grad school at CMU and  was working full-time as a developer in the valley before starting Kashmere Labs.
                    </p>
                    <div>Check out my Linkedin here.</div>
                    <div>Connect on Twitter here.</div>
                    </div>
                    </div>
                    </Col>
                </Grid>
            </div>
            )
    }
}