import React, {Component} from 'react';
import {Row, Col, ListGroup, ListGroupItem, Grid} from 'react-bootstrap';

export default class ServicesSection extends Component {
  render() {
    return (
      <div >
      <Grid>
          <div className="h2 section-heading">Services</div>
          <div className="service-section">
          <div className="product-banner">
            Custom App Development For Shopify. 
          </div>
          <Row>
          <Col lg={6}>
          <div>
          <ListGroup className="listing-container">
          <ListGroupItem>
            Custom Shopify App development. Fast, transparent, best-in-class execution.
          </ListGroupItem>
          <ListGroupItem>
           Now accepting quotes for Admin, Storefront Application and Theme development. 
          </ListGroupItem>
          <ListGroupItem>
            Full stack application development from ideation to implementation.
          </ListGroupItem>  
          <ListGroupItem>
            Quotes start from 60 $/hour.
          </ListGroupItem>
          <ListGroupItem>
           Book your calendly slot here or request a quote here.
          </ListGroupItem>
          </ListGroup>
          </div>
          </Col>
          <Col lg={6}>
            <img src={"logo/shopify_certified.png"} className="shopify-badge-image"/>
          </Col>
          </Row>
          </div>
          </Grid>
      </div>
      )
  }
}