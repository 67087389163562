import React, {Component} from 'react';

import {Row, Col, Grid} from 'react-bootstrap';

export default class LoyaltyplexPrivacy extends Component {
  render() {
    return (
      <Grid>
      <div style={{"textAlign": "left"}}>
      <div className="h2">Loyaltyplex Privacy Policy</div>
      <p>Loyaltyplex ("us", "we", or "our") operates the loyaltyplex.com website (the "Service").</p>

      <h2>Information Collection And Use</h2>

      <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

      <div>
        Loyaltyplex provides Customer retention services through the application of targeted discounts to merchants who 
        use Shopify to power their stores. This Privacy Policy describes how personal information is collected, used, 
        and shared when you install or use the App in connection with your Shopify-supported store.
      </div>
      <div className="h4"> 
        Personal Information the App Collects
      </div>
      <div>
        When you install the App, we are automatically able to access certain types of information from your Shopify account:
        store data, read_customers,read_script_tags,write_script_tags,read_price_rules,write_price_rules,read_orders 
      </div>
      <div>
        Additionally, the app also stores information about your defined policy, merchant email, and order details of customers who make purchases from your store.
      </div>
      <div>
        We collect personal information directly from the relevant individual, through your Shopify account, or using the following technologies: “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
        “Log files” track actions occurring on the app, including customer actions on the app and customer orders on the store.
      </div>
      <div className="h4">
        How Do We Use Your Personal Information?
      </div>
      <div>
          We use the personal information we collect from you and your customers in order to provide the Service and to operate the App. 
      </div>    
      <div className="h4">
      Sharing Your Personal Information
      </div>
      <div>
        We do not share your personal information with any third party for any commercial purposes and currently do not engage in any form of behavorial advertisiting.
      </div>
      <div>
        Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
      </div>
      <div>
        Your Rights If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
      </div>
      <div>
        Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.
      </div>
      <div className="h4">
      Data Retention
      </div>
      <div>
        When you place an order through the Site, we will maintain your Order Information for our records.
        unless and until you uninstall the app from the app-store or ask us to delete this information.
      </div>
      <div>
        Changes We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
      </div>
      <div>
        Contact Us For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at support@loyaltyplex.com or by mail using the details provided below:
        <p>Subject : Issue type</p>
        <p>Body: Issue details</p>
      </div>
      <div>
         Email support@loyaltyplex.com
      </div>
      </div>
      </Grid>
      )
  }
}