import React, {Component} from 'react';
import {Row, Col, Grid} from 'react-bootstrap';
import {Link} from 'react-router-dom';

export default class BottomNavBar extends Component {
  render() {
    return (
      
      <Grid fluid className="bottom-nav">
        <Row>
          <Col lg={1}>
           <Link to="/about">About</Link>
          </Col>
          <Col lg={2} lgOffset={8}>
            &copy; Kashmere Labs PTE Ltd. 2019.
          </Col>
        </Row>
      </Grid>
      )
  }
}