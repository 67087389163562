import React, {Component} from 'react';

export default class CartshareCarousel extends Component {
    render() 
    {
        return (
            <div>
            
            </div>
            )
    }
}